@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;800&display=swap');

:root {
  --dark-blue:hsl(209, 23%, 22%);
   --primary-blue: hsl(223, 87%, 63%);
  --verydark-blue:hsl(207, 26%, 17%);
  --dark-gray:hsl(0, 0%, 52%);
  --very-lighgray:hsl(0, 0%, 98%);
  --white:hsl(0, 0%, 100%);
  --ifm-primary: #09d3ac;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  line-height: 1.6;
  font-family: 'Nunito Sans', sans-serif;
  font-family: 14px;  
  font-family: 'Nunito Sans', sans-serif;
  font-family: 14px;  
  background-color: var(--verydark-blue) !important;
  color:var(--white);
  overflow-x: hidden;
}


ul {
  list-style: none;
}
img {
  width: 100%;
}

a {
  text-decoration: none;
}

p {
  margin: 10px 0;
}

.text-lg {
  font-size: 30px;
}



.text-center {
  text-align: center;
}

.main__area {
  margin-top: 50px;
}

.container {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 30px;
}

.navbar {
  height: 70px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}
.navbar .navbar-brand {
  width: 200px;
  margin: 0;
}

.navbar ul li a {
  color: #fff;
}


.main__area .grid-2{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.main__area .right{
  padding: 0 20px;
  height: 100vh;
  max-width: 700px;
  border-left: 1px solid #ccc;
}

.main__area .text-lg {
  margin-bottom: 20px;
}

.main__area form:first-of-type {
  margin-bottom: 20px;

}

.text__inner--use {
  padding: 12px;
  background-color: #000;
  border-left: 2px solid orange;
  line-height: 1.7;
}

.main__area form input[type="text"] {
  padding: 20px 25px;
  margin-right: 20px;
  width: 60%;
  border-radius: 5px;
  border: none;
}

.main__area form button {
  padding: 20px 40px;
  display: inline-block;
  cursor: pointer;
  background-color: var(--ifm-primary);
  opacity: .8;
  border: none;
  color: var(--white);
  outline: none;
  border-radius: 5px;
  font-size: 16px;
}

.main__area form input:focus {
  outline: none;
}

.main__area .file__form {
  position: relative;
  width: 100%;
}

.main__area .file__form label {
  display: inline-block;
  padding: 12px 18px;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--ifm-primary);
  font-size: 16px;
  font-weight: bold;
  color:var(--white);
  opacity:.7;
}



.main__area form input[type="file"] {
    display: none;
}
.file__form .text-white {
  margin-right: 15px;
}
.file__form svg {
  font-size: 25px;
  font-weight: bolder;
  color:#000;
}

.links__holder{
  display: block;
  height: 20vh;
  overflow: auto;
  background: #fff;
  background-color: #fff;
  overflow-x: hidden;
  padding: 20px;
}

.right h3 {
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 5px;
}
.links__holder .links a {
  color: var(--primary-blue);
  margin-bottom: 10px;
}

.right .btn-export {
  padding: 10px 20px;
  cursor: pointer;
  display: inline-block;
  border: none;
  outline: none;
  margin-bottom: 20px;
  font-size: 16px;
  background-color: rgb(211, 29, 232);
  color: var(--white);
  font-weight: 600;
  opacity: .9;
}

.right .btn-export span {
  margin-right: 10px;
}
.right .btn-export svg {
  font-size: 16px;
}
.descriptions{
  height: 20vh;
  overflow: auto;
}

.right__content h5 {
  font-weight: 600; 
}

.right__content .canonical_link {
  color: var(--ifm-primary);
}

.modal {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.show { 
  display: block;
}

.modal-body {
  background: white;
  width: 500px;
  margin: 20% auto;
}

.circular-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-top: #000;
  animation: circular-loader 2s linear infinite;
}

@keyframes circular-loader {
  from {
    transform: rotate(0deg);
  } 
  to {
    transform: rotate(360deg
    );
  }
}

@media(max-width: 768px) {
  .main__area .grid-2 {
    grid-template-columns: 1fr;
  }
}

@media(max-width: 500px) {
  .main__area .grid-2 {
    grid-template-columns: 1fr;
  }
}